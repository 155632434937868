import '@/styles/globals.css';
import '@/styles/account.css';
import '@/styles/home-decor.css';
import type { AppContext, AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { NextPageWithLayout } from '@/types/NextPageWithLayout';
import store, { RootState } from '@/store';
import { ReduxWrapper } from '@/components/common/ReduxWrapper';
// import Fonts from '@/styles/Fonts';
import App from 'next/app';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useRef } from 'react';
import {
  hideLogin,
  showLoginModal,
  showSignUp,
} from '@/store/features/loginSlice';
import dynamic from 'next/dynamic';
import { userService } from '@/services/userService';
import { useRouter } from 'next/router';
import { getCartProducts } from '@/store/features/cartSlice';
import { GoogleOAuthProvider } from '@react-oauth/google';
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  initialReduxState: RootState;
  isMobile: boolean;
};
const Fonts = dynamic(() => import('@/styles/Fonts'), { ssr: true });

const MyApp = ({ Component, pageProps, isMobile }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const router = useRouter();
  const isUserAuthorized = store.getState().user.authorized;
  // const currentUrl = router.asPath;
  const getLayout = Component.getLayout ?? ((page) => page);
  const getIsSignupOpen =
    typeof window !== 'undefined' && window.sessionStorage.getItem('signup');
  const currentUrlRef = useRef(router.asPath);

  const handleSignupOpen = () => {
    store.dispatch(showLoginModal());
    store.dispatch(hideLogin());
    store.dispatch(showSignUp());
  };

  useEffect(() => {
    currentUrlRef.current = router.asPath;
  }, [router.asPath]);

  const layoutRoutes: string[] = ['cart', 'guest', 'payment'];
  // const isNonLayoutPath = layoutRoutes.some((v) => currentUrl.includes(v));
  useEffect(() => {
    if (!getIsSignupOpen) {
      const timer = setTimeout(() => {
        const currentUrl = currentUrlRef.current; // Use the latest URL from the ref
        const isNonLayoutPath = layoutRoutes.some((v) =>
          currentUrl.includes(v)
        );
        if (
          !store.getState().loginModal.showModal &&
          !store.getState().user.authorized &&
          !isNonLayoutPath
        ) {
          handleSignupOpen();
          sessionStorage.setItem('signup', 'true');
        }
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [getIsSignupOpen]);

  useEffect(() => {
    const handler = () => {
      userService.checkTokenExpire();
    };

    window.addEventListener('load', handler);

    return () => document.removeEventListener('load', handler);
  }, []);

  useEffect(() => {
    if (isUserAuthorized) store.dispatch(getCartProducts());
  }, [router, isUserAuthorized]);

  // const getLayout = Component.getLayout ?? ((page) => <Layout isMobile={isMobile}>{page}</Layout>);

  return getLayout(
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_ID as string}>
      <Provider store={store}>
        <ReduxWrapper>
          <Fonts />
          <Component {...pageProps} isMobile={isMobile} />
          <ToastContainer
            position="top-right"
            autoClose={6000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            limit={2}
            transition={Bounce}
          />
        </ReduxWrapper>
      </Provider>
    </GoogleOAuthProvider>,
    pageProps
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const userAgent = appContext.ctx.req
    ? appContext.ctx.req.headers['user-agent']
    : navigator.userAgent;
  const isMobile = /mobile/i.test(userAgent || '');

  // Set cookies with SameSite=Strict to prevent third-party cookies (only on the server-side)
  if (appContext.ctx.res) {
    appContext.ctx.res.setHeader(
      'Set-Cookie',
      'mySecureCookie=value; SameSite=Strict; HttpOnly; Secure; Path=/'
    );
  }

  return { ...appProps, isMobile };
};

export default MyApp;
