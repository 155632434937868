import { apiService } from '@/services/ApiService';
import { setCookie, deleteCookie } from 'cookies-next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface StoreLocationState {
  location: any;
  storesList: any;
}

const initialState: StoreLocationState = {
  location: {},
  storesList: [],
};

export const getCityStoresList = createAsyncThunk(
  'getCityStoresList',
  async () => {
    const response = await apiService.get(`getCityStores`);
    return response;
  }
);
export const getStoreLocation = createAsyncThunk(
  'storeLocation/getStoreLocation',
  async (data: any) => {
    deleteCookie('city');
    deleteCookie('pincode');
    deleteCookie('state');
    localStorage.removeItem('location');
    const response: any = await apiService.get(
      `getStoreLocation?location=${data?.pin}`,
      '',
      '',
      false
    );
    if (Object.keys(response?.data).length > 0) {
      const rows = response?.data?.rows;
      if (rows.length > 0) {
        rows.forEach((address: any) => {
          if (address?.store_address?.length > 0) {
            const show = address?.store_address?.split(',');
            const len = show?.length;
            address.display =
              show[len - 4] +
              ',' +
              show[len - 3] +
              ',' +
              show[len - 2] +
              ',' +
              show[len - 1];
          }
        });
        if (!data?.isCity) {
          const pincode = rows[0]?.pincode;
          const city = rows[0]?.city;
          const state = rows[0]?.state;
          const pcity = city ? city[0]?.toUpperCase() + city?.substring(1) : '';
          city && setCookie('city', pcity);
          state && setCookie('state', state);
          pincode && setCookie('pincode', pincode);
          localStorage.setItem('location', JSON.stringify(rows));
          setStoreLocationDetails(response.data);
        }
      }
    } else setCookie('pincode', data?.pin);
    return response;
  }
);

const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState,
  reducers: {
    setStoreLocationDetails: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCityStoresList.fulfilled, (state, action: any) => {
      state.storesList = action.payload?.data.cities;
    });
  },
});

export const { setStoreLocationDetails } = storeLocationSlice.actions;
export const getStoreLocationDetails = ({ stores }: { stores: any }) =>
  stores.location;
export const getAllCityStoresList = ({ stores }: { stores: any }) => {
  return stores?.storesList;
};

export default storeLocationSlice.reducer;
