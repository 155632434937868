import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the initial state and its type
interface itemProps {
  category: any;
  products: any;
  topProducts: any;
}
interface CategoriesState {
  items: itemProps[];
  loading: boolean;
  error: string | null;
}

const initialState: CategoriesState = {
  items: [],
  loading: false,
  error: null,
};

// Define the type of API response for type safety
interface ApiResponse {
  products: any[];
}

// Base API URL
const BASE_API_URL = 'http://localhost:3001/api/v1/categories/products';

// Async thunk to fetch categories products
export const fetchCategoriesProducts = createAsyncThunk(
  'categories/fetchCategoriesProducts',
  async (item: Omit<any, 'id'>, { rejectWithValue }) => {
    try {
      const response = await axios.post(BASE_API_URL, item);
      return response.data; // API response
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch categories products'
      );
    }
  }
);

// Slice definition
const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesProducts.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(
        fetchCategoriesProducts.fulfilled,
        (state, action: PayloadAction<ApiResponse>) => {
          state.loading = false;
          state.items = action.payload.products || []; // Save fetched products
        }
      )
      .addCase(fetchCategoriesProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string; // Save error message
      });
  },
});

// Selector with explicit return type
export const getCategoriesProducts = ({
  categories,
}: {
  categories: CategoriesState;
}): any[] => categories?.items;

export default categoriesSlice.reducer;
