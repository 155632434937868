import { getCookie } from 'cookies-next';
import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface CustomerState {
  detail: any;
  address: any;
}

const initialState: CustomerState = {
  detail: {},
  address: {},
};

export const getCustomerDetail = createAsyncThunk(
  'customer/getCustomerDetail',
  async () => {
    if (!getCookie('woodenstreetUserAuthToken')) {
      localStorage.removeItem('customerDetails');
      return {};
    } else {
      const response: any = await apiService.get(`getDetails`, '', '', true);
      if (Object.keys(response?.data).length > 0) {
        const details = JSON.stringify(response.data.details);
        if (Object.keys(response?.data?.details).length > 0)
          localStorage.setItem('customerDetails', details);
        else localStorage.removeItem('customerDetails');
      }
      return response;
    }
  }
);
export const updateCustomerDetails = createAsyncThunk(
  'customer/updateCustomerDetails',
  async (data: any, { dispatch }) => {
    const response: any = await apiService.put(
      `updateCustomer`,
      '',
      data,
      true
    );
    if (Object.keys(response.data).length > 0) dispatch(getCustomerDetail());
    // store.dispatch(setCustomerDetails(response.data));
    return response;
  }
);
export const updateCustomerPassword = createAsyncThunk(
  'customer/updateCustomerPassword',
  async (data: any, { dispatch }) => {
    const response: any = await apiService.put(
      `updateCustomerPassword`,
      '',
      data,
      true
    );
    if (Object.keys(response.data).length > 0) dispatch(getCustomerDetail());
    return response;
  }
);

export const resetCustomerPassword = createAsyncThunk(
  'customer/resetCustomerPassword',
  async (data: any, {}) => {
    const response: any = await apiService.put(
      `updateCustomerPassword`,
      '',
      data,
      true
    );
    return response;
  }
);

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      state.detail = action.payload;
    },
    setCustomerAddress: (state, action) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerDetail.fulfilled, (state, action: any) => {
      state.detail = action?.payload?.data?.details || {};
    });
  },
});

export const { setCustomerDetails, setCustomerAddress } = customerSlice.actions;
export const getCustomerDetails = ({ customer }: { customer: any }) =>
  customer?.detail;
export const getCustomerAddress = ({ customer }: { customer: any }) =>
  customer?.address;

export default customerSlice.reducer;
