// features/loginModalSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface GalleryModalState {
  viewGalleryModal: boolean;
  activeTab: string;
}

const initialState: GalleryModalState = {
  viewGalleryModal: false,
  activeTab: 'ImageList11',
};

const galleryModalSlice = createSlice({
  name: 'galleryModal',
  initialState,
  reducers: {
    showGalleryModal: (state, action) => {
      state.viewGalleryModal = true;
      state.activeTab = action.payload;
    },
    hideGalleryModal: (state) => {
      state.viewGalleryModal = false;
      state.activeTab = 'ImageList11';
    },
  },
});

export const { showGalleryModal, hideGalleryModal } = galleryModalSlice.actions;

export const viewGalleryModal = ({ galleryModal }: { galleryModal: any }) =>
  galleryModal.viewGalleryModal;
export const viewActiveTab = ({ galleryModal }: { galleryModal: any }) =>
  galleryModal.activeTab;

export default galleryModalSlice.reducer;
