import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface CustomerState {
  address: any;
}

const initialState: CustomerState = {
  address: {},
};

export const getCustomerAddressData = createAsyncThunk(
  'customerAddress/getCustomerAddressData',
  async (data: any) => {
    const response: any = await apiService.get(
      `getCustomerAddress`,
      '',
      data,
      true
    );
    return response;
  }
);
export const addCustomerAddress = createAsyncThunk(
  'customerAddress/addCustomerAddress',
  async (data: any) => {
    const response: any = await apiService.post(
      `addCustomerAddress`,
      data,
      true
    );
    return response;
  }
);
export const updateCustomerAddress = createAsyncThunk(
  'customerAddress/updateCustomerAddress',
  async (data: any) => {
    const { id, ...post } = data;
    const response: any = await apiService.put(
      `updateCustomerAddress`,
      id,
      post,
      true
    );
    return response;
  }
);
export const deleteCustomerAddress = createAsyncThunk(
  'customerAddress/deleteCustomerAddress',
  async (id: any) => {
    const response: any = await apiService.delete(
      `deleteCustomerAddress`,
      id,
      true
    );
    return response;
  }
);

const customerAddressSlice = createSlice({
  name: 'customerAddress',
  initialState,
  reducers: {
    setCustomerAddress: (state, action) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerAddressData.fulfilled, (state, action: any) => {
      state.address = action?.payload?.data || {};
    });
    builder.addCase(addCustomerAddress.fulfilled, (state, action: any) => {
      state.address = action?.payload?.data || {};
    });
    builder.addCase(updateCustomerAddress.fulfilled, (state, action: any) => {
      state.address = action?.payload?.data || {};
    });
    builder.addCase(deleteCustomerAddress.fulfilled, (state, action: any) => {
      state.address = action?.payload?.data || {};
    });
  },
});

export const { setCustomerAddress } = customerAddressSlice.actions;
export const getCustomerAddress = ({ customer }: { customer: any }) =>
  customer?.address;

export default customerAddressSlice.reducer;
